


function AdminBlock(props) {

    const goBack = (allIfno) => {
        props.callBack(allIfno);// can pass callback data here
    }

    return (
    <button className={"adminBlock " + props.allInfo.runnerState} key={props.allInfo.firstName + props.allInfo.lastName} onClick={() => goBack(props.allInfo)}>
        <h1>{props.allInfo.completedLaps}</h1>
        <h2><span className="firstName">{props.allInfo.firstName}</span><br />{props.allInfo.lastName}</h2>
    </button>
    )
}
export default AdminBlock;