import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom'

import AdminList from './components/adminlist'
import ResultsTable from './components/resultstable'
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import LoginUser from './components/login';
import ScreenTable from './components/screentable'


// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBquTZItciS7iCeSlAc1n2QDtf65E6uPJk",
  authDomain: "dirty-dozen-auth.firebaseapp.com",
  projectId: "dirty-dozen-auth",
  storageBucket: "dirty-dozen-auth.appspot.com",
  messagingSenderId: "790690878675",
  appId: "1:790690878675:web:3f9ac9009ac6e13738f026"
};



const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ResultsTable />} />
        <Route path="/login" element={<LoginUser  />} />
        <Route path="/manage" element={<AdminList />} />
        <Route path="/screen" element={<ScreenTable />} />
      </Routes>
    </BrowserRouter>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
console.log('%c Made with Node, React and the Google Sheets API', 'background: #222; color: #bada55');
reportWebVitals();