import tickImage from '../assets/tick.png'

function ScreenRow(props) {

    const rows = [];
    const milesCompleted = []
    let thisRacetime = 0
    const isWinner = []
    
    try {
        if (props.raceTime > 0) {
        // Runner is active

        if (props.nextLapState.toLowerCase() === "active") {
            if (props.raceTime > 12) {
                thisRacetime = 12
                isWinner.push(<span className="completedSpan" key={"completed"+props.firstName}>⭐</span>)
            } else {
                thisRacetime = props.raceTime
            }
            let timeLeft = 12 - thisRacetime
            if (thisRacetime > 12) {
                milesCompleted.push(50)
            } else {
                milesCompleted.push((thisRacetime * 4.2).toFixed(0))
            }
            
            for (let i = 0; i < thisRacetime; i++) {
                rows.push(<td key={"completed"+i} className='completed'><img src={tickImage} alt="Logo" /></td> );  
            }

            for (let i = 0; i < timeLeft; i++) {
                rows.push(<td key={"incompleted"+i}></td> );
            }
        }

        if (props.nextLapState.toLowerCase() === "winner") {  
            milesCompleted.push(50)
            isWinner.push(<span className="winnerSpan" key={"completed"+props.firstName}> 🏆 Winner</span>)
            for (let i = 0; i < 12; i++) {
                rows.push(<td key={"completed"+i} className='completed'><img src={tickImage} alt="Logo" /></td> );
            }
        }

        // Runner is DNS or DNF

        if (props.nextLapState.toLowerCase() === "dnf" | props.nextLapState.toLowerCase() === "dns") {
            milesCompleted.push((props.lapsCompleted * 4.2).toFixed(0))
            let timeLeft = 11 - props.lapsCompleted

            for (let i = 0; i < props.lapsCompleted; i++) {
                rows.push(<td key={"completed"+i} className='completed'><img src={tickImage} alt="Logo" /></td> );
            }

            rows.push(<td key={"deadLap-"+props.firstName} className={"failed"+props.nextLapState.toLowerCase()}>{props.nextLapState.toLowerCase()}</td>);

            for (let i = 0; i < timeLeft; i++) {
                rows.push(<td key={"incompleted"+i}></td> );
            }
        } 
        } else {
            for (let i = 0; i < 12; i++) {
                rows.push(<td></td> );
            }
    }
    } catch (e) {
        console.log(e)
        for (let i = 0; i < 12; i++) {
            rows.push(<td key={"completed"+i} className=''>LOADING</td> );
        }
    }

    return (
        <tr className={props.nextLapState.toLowerCase()} key={props.firstName + props.lastName}>
            <td className="runnerName">{props.firstName} {props.lastName} {isWinner}</td>
            {rows}
        </tr>
    )
}


export default ScreenRow;