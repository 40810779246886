
import { useState, useEffect, React} from "react";
import moment from 'moment';
import ScreenHeader from './screenHeader'
import ScreenRow from "./screenrow";
import logo from '../assets/dirty.png'


function raceInfo(startTime) {
    const currentDate = moment();
    const duration = moment.duration(currentDate.diff(moment(startTime, 'YYYYMMDDHHmmss')))
    const totalHours = duration.asHours();
    if (totalHours > 0 && totalHours < 15) {
        return "The event started at " + moment(startTime, 'YYYYMMDDHHmmss').format('H:mma')
    } else if (totalHours < 0 ){
        return ("The event has not yet started.")
    } else {
        return ("Congratulations to everyone who took part.")
    }
}

window.myAppData = {
    sortOrder: 1
};

const getComputedStyle = (passedField) => {
    if (passedField === "name" && window.myAppData.sortOrder === 1){
        return "activeSort"
    } else if (passedField === "bib" && window.myAppData.sortOrder === 4){
        return "activeSort"
    } else if (passedField === "miles" && window.myAppData.sortOrder === 2){
        return "activeSort"
    } else {
        return "nothing"
    }
}
  // Custom sorting function
  const customSort = (a, b) => {
    // Sort by the 3rd item (index 2)
    if (a.completedLaps === 0) {
      return -1;
    } else if (b.completedLaps === 0) {
      return 1;
    }

    const thirdItemComparison = parseInt(b.completedLaps) - parseInt(a.completedLaps);
    if (thirdItemComparison !== 0) {
      return thirdItemComparison;
    }

    // If 3rd item is "0" and 4th item is "DNS", place it at the bottom
    if (a.completedLaps === "0" && a.runnerState === "DNS") {
      return 1;
    }
    if (b.completedLaps === "0" && b.runnerState === "DNS") {
      return -1;
    }

    // Sort by the 4th item (index 3) 'DNF' < 'DNS'
    if (a.completedLaps !== b.completedLaps) {
      return a.completedLap === 'DNF' ? -1 : 1;
    }

    // If 3rd and 4th items are the same, sort alphabetically by 2nd item (index 1)
    return a.lastName.localeCompare(b.lastName);
  };

const ScreenTable = () => {
    require('../assets/style.css');  
    const [data, setData] = useState([]);
    const [startTime, startData] = useState([]);
    const [raceTime, raceTimeData] = useState([])

    const reOrder = (order) => {
        window.myAppData.sortOrder = order
        outPutData()
    }

    const outPutData  = ()  => {

        try {
            // fetch('http://localhost/data/sheets') // Replace with your JSON data URL
            fetch('/data/tabular') // Replace with your JSON data URL
                .then((response) => response.json())
                .then((jsonData) => {
                    const timeData = jsonData.theStartTime
                    const currentDate = moment();
                    const duration = moment.duration(currentDate.diff(moment(timeData, 'YYYYMMDDHHmmss'))).asHours()
                    const sortedData = [...jsonData.resultData];
                    if (window.myAppData.sortOrder === 1) {
                        sortedData.sort((a, b) => a.lastName.localeCompare(b.lastName));

                    } else if (window.myAppData.sortOrder === 2){
                        sortedData.sort(customSort)
                    } else {
                        sortedData.sort((a, b) => parseInt(a.bibNumber) - parseInt(b.bibNumber))
                    }
                    
        
                    let returnedData = {
                        duration: duration,
                        runners: sortedData,
                        startInfo: timeData
                    }
        
                    setData(returnedData.runners);  
                    raceTimeData(returnedData.duration)
                    startData(returnedData.startInfo);

                })
            } catch (error) {
                console.log(error);
            }

    }
   
    useEffect(() => {
    outPutData()
    const interval = setInterval(() => {
        outPutData()
        }, 5000); 

        return () => clearInterval(interval); 
    }, []);


    
    return (
        <div className="screenVersion">
       
        <table className="tg" id="Anglesey">
        <tbody>
            <tr className="times mobileHideCol">
                <th className="notWidth"> </th>
                <th >9AM</th>
                <th >10AM</th>
                <th >11AM</th>
                <th >12PM</th>
                <th >1PM</th>
                <th >2PM</th>
                <th >3PM</th>
                <th >4PM</th>
                <th >5PM</th>
                <th >6PM</th>
                <th >7PM</th>
                <th >8PM</th>
            </tr>
            <tr className="noEntryFade">
                <th><ScreenHeader header="The Dirty Dozen 2023 - Live Results" logo={logo} tenkstart={raceInfo(startTime)} raceTime={startTime}  /></th>
                <th className="narrowCol">1</th>
                <th className="narrowCol">2</th>
                <th className="narrowCol">3</th>
                <th className="narrowCol">4</th>
                <th className="narrowCol">5</th>
                <th className="narrowCol">6</th>
                <th className="narrowCol">7</th>
                <th className="narrowCol">8</th>
                <th className="narrowCol">9</th>
                <th className="narrowCol">10</th>
                <th className="narrowCol">11</th>
                <th className="narrowCol">12</th>
            </tr>
            {/* {raceTime > 0 && */}
            {
                data.map((key, index)=> (
                    <ScreenRow key={key.firstName + key.lastName} firstName={key.firstName} lastName={key.lastName} raceTime={parseInt(raceTime)} lapsCompleted={key.completedLaps} nextLapState={key.runnerState} runnerBib={key.bibNumber}/>
                ))
            }
            </tbody>
        </table>
        </div>
      
    );
}



export default ScreenTable;