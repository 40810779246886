
import { useState, useEffect, React} from "react";
import {Link} from 'react-router-dom'
import moment from 'moment';
import AdminBlock from '../components/adminBlock'
import Modal from 'react-modal';
import useUser from "../components/useUser"


const customStyles = {
    overlay: {
        backgroundColor: 'rgba(1, 1, 1, 0.75)'
    },
    content: {
      top: '195px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '10px 20px 5px 20px'
    },
  };

Modal.setAppElement('#root');

window.myAppData = {
    sortOrder: 2
};

const getComputedStyle = (passedField) => {
    if (passedField === "name" && window.myAppData.sortOrder === 1){
        return "activeSort"
    } else if (passedField === "bib" && window.myAppData.sortOrder === 4){
        return "activeSort"
    } else if (passedField === "miles" && window.myAppData.sortOrder === 2){
        return "activeSort"
    } else {
        return "nothing"
    }
}
  // Custom sorting function
  const customSort = (a, b) => {
    // Sort by the 3rd item (index 2) with '0' being the highest
    if (a[2] === "0" && b[2] === "0") {
      // If both have 3rd item equal to "0", sort by the 4th item (index 3) 'DNF' < 'DNS'
      if (a[3] === b[3]) {
        // If 4th item is the same, sort alphabetically by 2nd item (index 1)
        return a[1].localeCompare(b[1]);
      }
      return a[3] === 'DNF' ? 1 : -1;
    } else if (a[2] === "0") {
      return -1;
    } else if (b[2] === "0") {
      return 1;
    } else {
      // Sort by the 3rd item in descending order
      const thirdItemComparison = parseInt(b[2]) - parseInt(a[2]);

      // If 3rd item is equal, sort by the 4th item (index 3) 'DNF' < 'DNS'
      if (thirdItemComparison === 0) {
        return a[3] === b[3] ? a[1].localeCompare(b[1]) : a[3] === 'DNS' ? 1 : -1;
      }

      return thirdItemComparison;
    }
  };

const AdminList = () => {



    require('../assets/admin.css')
    const [data, setData] = useState([]);
    const [participantData, setParticipantData] = useState([])
    const [formError, setformError] = useState([])

    const reOrder = (order) => {
        window.myAppData.sortOrder = order
        outPutData()
    }



    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);


    function openModal(key) {
        setParticipantData(key)
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    async function submitForm(event) {

        event.preventDefault();
        const data = new FormData(event.target);
        const dataBlob = []
        dataBlob.push(data.get('fname'))
        dataBlob.push(data.get('lname'))
        dataBlob.push(data.get('laps'))
        dataBlob.push(data.get('state'))
        dataBlob.push(data.get('bib'))
        dataBlob.push(data.get('id'))



        closeModal()
        const token = await user.getIdToken()
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authtoken': token },
            body: JSON.stringify({dataBlob})
        };
        
        fetch('/data/tabular', requestOptions)
            .then(async response => {
                outPutData()
            })
            .catch(error => {
                setformError(error)
                this.setState({ errorMessage: error.toString() });
                console.error('There was an error!', error);
                
            });
    }

    const outPutData  = ()  => {

        try {
            // fetch('http://localhost/data/sheets')  // Replace with your JSON data URL
            fetch('/data/tabular') // Replace with your JSON data URL
                .then((response) => response.json())
                .then((jsonData) => {
                    const timeData = jsonData.theStartTime
                    const currentDate = moment();
                    const duration = moment.duration(currentDate.diff(moment(timeData, 'YYYYMMDDHHmmss'))).asHours()
                    const sortedData = [...jsonData.resultData];
                    if (window.myAppData.sortOrder === 1) {
                        sortedData.sort((a, b) => a.lastName.localeCompare(b.lastName));

                    } else if (window.myAppData.sortOrder === 2){
                        sortedData.sort(customSort)
                    } else {
                        sortedData.sort((a, b) => parseInt(a.bibNumber) - parseInt(b.bibNumber))
                    }
                    
        
                    let returnedData = {
                        duration: duration,
                        runners: sortedData,
                        startInfo: timeData
                    }
        
                    setData(returnedData.runners);  

                })
            } catch (error) {
                console.log(error);
            }

    }
   
    useEffect(() => {
    outPutData()
    // const interval = setInterval(() => {
    //     outPutData()
    //     }, 5000); 

    //     return () => clearInterval(interval); 
    }, []);
    const {user, isLoading} = useUser() 
    return (
       
        <>
        {user ?
        <div className="live-container adminArea">
            <div className="buttonHolder"><div className="order">Order by: </div>
                <button className={"headerTitle " + getComputedStyle("name")} onClick={() => reOrder(1)}>Name</button>
                <button className={"headerTitle " + getComputedStyle("bib")} onClick={() => reOrder(4)}>Bib</button>
                {/* <button className={"headerTitle " + getComputedStyle("miles")} onClick={() => reOrder(2)}>Miles</button> */}
            </div>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Participants"
            >
                
                <button onClick={closeModal} className="closeButton">X</button>
                <form onSubmit={submitForm}>
                    <label htmlFor="fname">First name:</label>
                    <input type="text" id="fname" name="fname" defaultValue={participantData.firstName} /><br/>
                    <label htmlFor="lname">Last name:</label>
                    <input type="text" id="lname" name="lname" defaultValue={participantData.lastName}/><br/>
                    <label htmlFor="lname">Bib:</label>
                    <input type="number" id="bib" name="bib" defaultValue={participantData.bibNumber}/><br/>
                    <label htmlFor="lname">Laps:</label>
                    <input type="number" id="laps" name="laps" defaultValue={participantData.completedLaps}/><br/>
                    <label htmlFor="lname">State:</label>
                    <select id="state" name="state">
                        <option value="Active" selected={participantData.runnerState === "Active"} >Active</option>
                        <option value="DNF" selected={participantData.runnerState === "DNF"}>DNF</option>
                        <option value="DNS" selected={participantData.runnerState === "DNS"}>DNS</option>
                        <option value="Winner" selected={participantData.runnerState === "Winner"}>Winner</option>
                    </select><br />
                    {/* <input type="text" id="state" name="state" defaultValue={participantData[3]}/><br/> */}
                 
                    <input type="hidden" id="id" name="id" defaultValue={participantData.id}/><br/>
                    <button  className="submitButton">Submit</button>
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{formError}</h2>
                </form>
            </Modal>
            <hgroup className="bockHolder">
                {
                    data.map((key, index)=> (
                        <AdminBlock key={"AdminBlock"+index} allInfo={key} callBack={openModal} />
                    ))
                }
            </hgroup>
        </div>
        : <Link className="loginLink"to="/login">Login</Link>
        }
        </>
  
    )
}



export default AdminList;