import { useState, useEffect } from "react";
import moment from 'moment';


function convertHoursToHMS(hours, upOrDown) {

    if (upOrDown === "down") {
        hours = Math.abs(hours);
    }

    const hoursRemaining = Math.floor(hours);
    const minutesRemaining = Math.floor((hours - hoursRemaining) * 60);
    const secondsRemaining = Math.floor((hours - hoursRemaining) * 3600 % 60);
    const result = {}

    if (hoursRemaining > 0) {
        result.hours = hoursRemaining;
    }
    
    if (minutesRemaining > 0) {
        result.minutes = minutesRemaining;
    }
    
    if (secondsRemaining > 0) {
        result.seconds = secondsRemaining;
    }

    let output = ''
    let totalDays = ''
    let remainingHours = ""
    if (result.hours) {
        if (result.hours > 24) {
            totalDays = parseInt(result.hours / 24)
            remainingHours = result.hours - (totalDays * 24)
            output += `${totalDays + " days " + remainingHours}:`;
        } else {
            output += `${result.hours}:`;
        }
        
    }

    if (result.minutes  >= 10) {
        output += result.minutes+":" ;
    } else if (result.minutes  >= 0) {
        output += `0${result.minutes }:`;
    } else if (result.minutes  === undefined) {
        output += `00:`;
    }

    if (result.seconds  >= 10) {
        output += result.seconds ;
    } else if (result.seconds  >= 0) {
        output += `0${result.seconds }`;
    } else if (result.seconds  === undefined) {
        output += `00`;
    }

    return {
        output 
    };
  }


function ScreenHeader(props) {
    const [raceTime, startData] = useState([]);

    const raceTimeCalc = (startTime) => {
        
        const currentDate = moment();
        const clockDuration = moment.duration(currentDate.diff(moment(startTime, 'YYYYMMDDHHmmss')))
        const totalHours = clockDuration.asHours();
        const hours = clockDuration.hours()

        if (totalHours < 12 && totalHours > 0) {
            const timeParts = convertHoursToHMS(totalHours, "up");
            return ("Time: "+ timeParts.output )
        } else if (totalHours < 0) {
            const timeParts = convertHoursToHMS(totalHours, "down");
            return (timeParts.output)
        }

        if (hours > 12) {
            return ("Finished")
        }

        if (hours < 0) {
            return (moment(startTime, 'YYYYMMDDHHmmss'))
        }

        return;
    }

    useEffect(() => {
        startData(raceTimeCalc(props.raceTime)); 
        const interval = setInterval(() => {
            startData(raceTimeCalc(props.raceTime)); 
          }, 1000); 

          return () => clearInterval(interval); 
      }, [props.raceTime]);

    return (
        <h3><span className="brandColor">{raceTime}</span></h3>
    );
}

export default ScreenHeader;